  #appBar {
    background-color: orange;
    padding: 10px;
  }

  #appBarText {
    font-size: 1.2em;
    font-weight: bold;
    color: white;
  }

  #body {
   
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
  }

  #welcomeText {
    font-size: 20px;
    font-weight: bold;
    color: orange;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  #balanceCard {
    width: 85%;
    max-width: 400px;
    background-color: orange;
    
    border-radius: 12px;
    
  }

  #balanceText {
    font-size: 20px;
    color: white;
    font-weight: bold;
    /* margin: 5px; */
  }

  #main {
    margin-top: 20px;
    width: 90%;
    max-width: 400px;
    /* height: 50vh; */
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cardMenu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 93%;
    margin: auto;
  }

  .smallCard {
    border-radius: 10px;
    margin: 20px 0;
    padding: 10px;
    width: 45%;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: aliceblue;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 120px;
    position: relative; /* Added position relative */
  }
  
  .cardText {
    color: black;
    font-weight: bold;
    flex-grow: 1; /* Allow text to grow within the available space */
  }
  
  .btn {
    width: 100px;
    height: 40px;
    position: absolute; /* Added position absolute */
    bottom: 20px; /* Adjust as needed to position the button at the bottom */
    border-radius: 5px;
    background-color: orange;
    color: white;
    cursor: pointer;
  }
  